export default {
    data () {
        return {
            swRefreshing: false,
            swRegistration: null,
            swUpdateExists: false
        }
    },
    methods: {
        // Store the SW registration so we can send it a message
        // We use `swUpdateExists` to control whatever alert, toast, dialog, etc we want to use
        // To alert the user there is an update they need to refresh for
        swUpdateAvailable (event) {
            const vm = this
            vm.swRegistration = event.detail
            vm.swUpdateExists = true
        },
        // Called when the user accepts the update
        swRefreshApp () {
            const vm = this
            vm.swUpdateExists = false
            // Make sure we only send a 'skip waiting' message if the SW is waiting
            if (!vm.swRegistration || !vm.swRegistration.waiting) {
                return
            }
            // send message to SW to skip the waiting and activate the new SW
            vm.swRegistration.waiting.postMessage({type: 'SKIP_WAITING'})
        }
    },
    created () {
        const vm = this
        // Listen for our custom event from the SW registration
        document.addEventListener('swUpdated', vm.swUpdateAvailable, {once: true})

        // Prevent multiple refreshes
        navigator.serviceWorker.addEventListener('controllerchange', () => {
            if (vm.swRefreshing) {
                return
            }
            vm.swRefreshing = true
            // Here the actual reload of the page occurs
            window.location.reload()
        })
    }
}
