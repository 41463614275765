<template>
    <!-- BaseAppNavigation.vue -->
    <v-navigation-drawer
        :value="value"
        app
        clipped
        color="white"
        right
        @input="handleUpdateNav"
    >
        <!-- Nav Items -->
        <v-list :class="{'pa-0': !value}" dense nav>
            <v-fade-transition>
                <v-list-item v-if="value" class="my-0 py-0" dense>
                    <v-spacer></v-spacer>
                    <v-list-item-action>
                        <v-btn color="accent" icon @click="handleClickClose">
                            <v-icon large>mdi-close</v-icon>
                        </v-btn>
                    </v-list-item-action>
                </v-list-item>
            </v-fade-transition>
            <v-list-item
                :exact="true"
                :to="sectionRouteFormatted()"
                class="justify-center"
                color="primary"
            >
                <!-- Title -->
                <v-list-item-content>
                    <v-list-item-title class="accent--text text-subtitle-1 text--lighten-1">
                        {{ $t('home_link') }}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <!-- Nav Items -->
            <v-list-item
                v-for="item in sectionLinks"
                :key="item.name"
                :exact="true"
                :to="item.route"
                class="justify-center"
                color="primary"
            >
                <!-- Title -->
                <v-list-item-content>
                    <v-list-item-title class="accent--text text-subtitle-1 text--lighten-1">
                        {{ item.name }}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
    import SectionsMixin from '@/mixins/sections.mixin'

    export default {
        name: 'BaseAppNavigation',
        mixins: [SectionsMixin],
        props: {
            /**
             * Toggle the visibility of the drawer
             */
            value: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            handleClickClose () {
                this.handleUpdateNav(false)
            },
            handleUpdateNav (val) {
                /**
                 * @event update:nav
                 * @type {boolean}
                 */
                this.$emit('update:nav', val)
            }
        }
    }
</script>
