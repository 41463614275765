<template>
    <!-- BaseAppBar.vue -->
    <v-app-bar
        :app="app"
        :color="colorFormatted"
        :fixed="fixed"
        dark
        flat
    >
        <!-- Title -->
        <v-btn
            :to="sectionRouteFormatted()"
            active-class="no-active"
            class="no-active px-0 font-weight-medium text-h5 text-uppercase"
            elevation="0"
            text
        >
            <v-icon class="mr-3" color="info">mdi-heart</v-icon>
            {{ title }}
        </v-btn>
        <v-spacer></v-spacer>
        <!-- Desktop Nav -->
        <template v-if="!$vuetify.breakpoint.mobile">
            <v-btn
                v-for="section in sectionLinks"
                :key="section.name"
                :to="section.route"
                class="font-weight-bold text-subtitle-1"
                elevation="0"
                text
            >
                {{ section.name }}
            </v-btn>
        </template>
        <!-- Mobile Nav Icon -->
        <v-app-bar-nav-icon
            v-else
            x-large
            @click="handleClickNavIcon"
        ></v-app-bar-nav-icon>
        <!-- Sign Out Button -->
        <v-tooltip v-if="authenticated" :disabled="$vuetify.breakpoint.mobile" bottom>
            <template v-slot:activator="{on, attrs}">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="error"
                    icon
                    @click="handleClickSignOut"
                >
                    <v-icon>mdi-exit-to-app</v-icon>
                </v-btn>
            </template>
            Sign Out
        </v-tooltip>
    </v-app-bar>
</template>

<script>
    import {APP_TITLE} from '@/store/static/app'
    import {HOME} from '@/router/route-names'
    import SectionsMixin from '@/mixins/sections.mixin'

    export default {
        name: 'BaseAppBar',
        mixins: [SectionsMixin],
        props: {
            /**
             * Designate the bar as part of the application layout
             */
            app: {
                type: Boolean,
                default: true
            },
            /**
             * Whether the user is authenticated
             */
            authenticated: {
                type: Boolean,
                default: false
            },
            /**
             * The color of the app bar
             */
            color: {
                type: String,
                default: 'rgba(156,185,209,.8)'
            },
            /**
             * Set the position to fixed
             */
            fixed: {
                type: Boolean,
                default: true
            },
            /**
             * The scroll position from the top
             */
            scrollY: {
                type: Number,
                required: true
            },
            /**
             * The title of the bar
             */
            title: {
                type: String,
                default: APP_TITLE
            }
        },
        computed: {
            colorFormatted () {
                const vm = this

                if (vm.isHome && vm.scrollY === 0) {
                    return 'transparent'
                }

                return vm.color
            },
            isHome () {
                return this.$route.name === HOME
            }
        },
        methods: {
            handleClickNavIcon () {
                this.$emit('click:nav-icon')
            },
            handleClickSignOut () {
                this.$emit('click:sign-out')
            }
        }
    }
</script>

<i18n>
{
    "en": {
        "title": "Hello i18n in SFC!"
    }
}
</i18n>
