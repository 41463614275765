<!-- App.vue -->
<template>
    <v-app>
        <!-- App Bar -->
        <base-app-bar
            v-if="!isInvitation"
            :authenticated="Boolean($firebase.currentUser())"
            :scroll-y="scrollY"
            @click:nav-icon="showNav = !showNav"
            @click:sign-out="handleSignOut"
        ></base-app-bar>

        <!-- App Navigation -->
        <base-app-navigation
            v-if="$vuetify.breakpoint.mobile"
            :value="showNav"
            @update:nav="val => showNav = val"
        ></base-app-navigation>

        <!-- Snackbar Errors -->
        <v-snackbar-queue
            :items="snackbarItems"
            close-button-icon="mdi-close"
            right
            top
            @remove="handleRemoveSnackbarItem"
        ></v-snackbar-queue>

        <!-- Main -->
        <v-main :class="{'pt-0': isHome}">
            <!-- Router View -->
            <router-view></router-view>
            <!--  SW Update Notification -->
            <service-worker-snackbar
                :value="swUpdateExists"
                @click="swRefreshApp"
            ></service-worker-snackbar>
            <!-- Back to top button-->
            <speed-dial-button
                v-if="!isInvitation"
                v-show="scrollY > 0"
                @click="$vuetify.goTo(0)"
            ></speed-dial-button>
        </v-main>

        <!-- Footer -->
        <base-app-footer
            :locale-value="$i18n.locale"
            @click:icon="handleSignIn"
            @click:locale="handleSetLocale"
        ></base-app-footer>
    </v-app>
</template>

<style type="text/css">
    .v-btn--active.no-active::before {
        opacity: 0 !important;
    }
</style>

<script>
    import {each, findIndex} from 'underscore'
    import {mapActions, mapGetters} from 'vuex'
    import {ADMIN_LOGIN, HOME, INVITATION} from '@/router/route-names'
    import {APP_FOOTER, APP_TITLE} from '@/store/static/app'
    import logger from '@/utils/logger'
    // Mixins
    import ServiceWorkerMixin from '@/mixins/service-worker.mixin'
    // Components
    import BaseAppBar from '@/components/BaseAppBar'
    import BaseAppFooter from '@/components/BaseAppFooter'
    import BaseAppNavigation from '@/components/BaseAppNavigation'
    import ServiceWorkerSnackbar from '@/components/ServiceWorkerSnackbar'
    import SpeedDialButton from '@/components/SpeedDialButton'

    export default {
        name: 'App',
        mixins: [ServiceWorkerMixin],
        data: () => ({
            APP_FOOTER,
            APP_TITLE,
            scrollY: 0,
            showNav: false,
            snackbarItems: []
        }),
        components: {
            BaseAppBar,
            BaseAppFooter,
            BaseAppNavigation,
            ServiceWorkerSnackbar,
            SpeedDialButton
        },
        computed: {
            ...mapGetters('errors', ['errors']),
            isInvitation () {
                const vm = this
                return vm.$route.name === INVITATION
            },
            isHome () {
                const vm = this
                return vm.$route.name === HOME
            }
        },
        methods: {
            ...mapActions('auth', ['signOut']),
            ...mapActions('errors', ['removeErrors']),
            handleAddSnackbarItem (error) {
                this.snackbarItems.push(error)
            },
            handleRemoveSnackbarItem (uuid) {
                const vm = this
                const index = findIndex(vm.snackbarItems, {uuid})

                if (index !== -1) {
                    vm.snackbarItems.splice(index, 1)
                }
            },
            handleSignIn () {
                this.$router.push({name: ADMIN_LOGIN})
            },
            async handleSignOut () {
                try {
                    await this.signOut()
                    await this.$router.push({path: '/'})
                } catch (e) {
                    logger.error(e)
                }
            },
            async handleSetLocale (value) {
                const vm = this
                try {
                    vm.$i18n.locale = value
                    await localStorage.setItem('locale', value)
                } catch (e) {
                    logger.error(e)
                }
            },
            onScroll () {
                this.scrollY = window.scrollY
            }
        },
        watch: {
            '$vuetify.breakpoint.mobile' (newVal) {
                const vm = this
                if (vm.showNav && !newVal) {
                    vm.showNav = false
                }
            },
            errors (errors) {
                const vm = this

                each(errors, (data) => {
                    if (data.toString && typeof data.toString === 'function') {
                        logger.error(data.toString())
                    }

                    vm.handleAddSnackbarItem({
                        ...data,
                        id: data.uuid,
                        message: (data.message || vm.$t('unknown_error'))
                    })

                    vm.removeErrors([data.uuid])
                })
            }
        },
        async created () {
            const vm = this
            window.addEventListener('scroll', vm.onScroll)

            try {
                let locale = await localStorage.getItem('locale')

                if (!locale) {
                    locale = ['en', 'es'].includes(navigator.language) && navigator.language
                }

                if (locale) {
                    this.$i18n.locale = locale
                }
            } catch (e) {
                logger.error(e)
            }
        },
        destroyed () {
            const vm = this
            window.removeEventListener('scroll', vm.onScroll)
        }
    }
</script>
