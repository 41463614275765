<template>
    <!-- BaseLocaleSelect.vue -->
    <v-menu style="z-index: 1001;">
        <template v-slot:activator="{ on, attrs }">
            <!-- Selected Locale -->
            <v-btn
                v-bind="attrs"
                v-on="on"
                :min-width="width"
                :width="width"
                style="padding: 0 !important;"
                text
            >
                <base-country-flag
                    :country-code="selectedLocale.flag"
                    :width="width"
                ></base-country-flag>
            </v-btn>
        </template>
        <!--Available Locales -->
        <v-list>
            <v-list-item
                v-for="item in localeItems"
                :key="item.value"
                @click="handleClickLocale(item.value)"
            >
                <base-country-flag
                    :country-code="item.flag"
                ></base-country-flag>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
    // Mixins
    import BaseLocaleMixin from '@/mixins/base-locale.mixin'
    // Components
    import BaseCountryFlag from '@/components/BaseCountryFlag'

    export default {
        name: 'BaseLocaleSelect',
        mixins: [BaseLocaleMixin],
        props: {
            /**
             * The width of the button
             */
            width: {
                type: [Number, String],
                default: 32
            }
        },
        components: {
            BaseCountryFlag
        }
    }
</script>
