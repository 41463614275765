export const HOME = 'home'
export const STORY = 'story'
export const WEDDING = 'wedding'
export const GIFTS = 'gifts'

export default {
    HOME,
    STORY,
    WEDDING,
    GIFTS
}
