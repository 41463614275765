<template>
    <!-- SpeedDialButton.vue -->
    <v-speed-dial
        bottom
        fixed
        right
        style="right: 10px"
    >
        <template v-slot:activator>
            <v-tooltip :color="color" :disabled="$vuetify.breakpoint.mobile" left>
                <template v-slot:activator="{on, attrs}">
                    <v-btn
                        v-bind="attrs"
                        v-on="on"
                        :color="color"
                        dark
                        fab
                        @click="handleClick"
                    >
                        <v-icon>
                            {{ icon }}
                        </v-icon>
                    </v-btn>
                </template>
                {{ tooltipText }}
            </v-tooltip>
        </template>
    </v-speed-dial>
</template>

<script>
    export default {
        name: 'SpeedDialButton',
        props: {
            /**
             * The color of the button
             */
            color: {
                type: String,
                default: 'info'
            },
            /**
             * The icon of the button
             */
            icon: {
                type: String,
                default: 'mdi-arrow-up'
            },
            /**
             * The tooltip text of the button
             */
            tooltipText: {
                type: String,
                default: 'Back to Top'
            }
        },
        methods: {
            handleClick () {
                /**
                 * @event click
                 */
                this.$emit('click')
            }
        }
    }
</script>
