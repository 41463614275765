import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/es5/services/goto'
import routeNames from './route-names'
import {currentUser} from '@/firebase'
import {HOME} from '@/store/static/sections'

const BaseNotFound = () => import(/* webpackChunkName: "app-wrapper" */ '../components/BaseNotFound')
const Main = () => import(/*webpackChunkName: "home"*/'../views/Main')
const Invite = () => import(/*webpackChunkName: "invite"*/'../views/Invite')
const Admin = () => import(/*webpackChunkName: "admin"*/'../views/Admin')
const Login = () => import(/*webpackChunkName: "admin"*/'../views/Login')

Vue.use(VueRouter)

let routes = [
    {
        path: '/',
        name: routeNames.HOME,
        component: Main
    },
    {
        path: '/invitation/:id',
        name: routeNames.INVITATION,
        props: true,
        component: Invite
    },
    {
        path: '/admin',
        name: routeNames.ADMIN,
        meta: {requiresAuth: true},
        component: Admin
    },
    {
        path: '/admin/login',
        name: routeNames.ADMIN_LOGIN,
        component: Login
    },
    {
        path: '*',
        component: BaseNotFound
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0

        if (to.params && to.params.scroll === false) {
            return
        }

        if (to.hash && to.hash !== `#${HOME}`) {
            scrollTo = to.hash
        } else if (savedPosition) {
            scrollTo = savedPosition.y
        }

        return goTo(scrollTo)
    },
    routes
})

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth && !currentUser()) {
        next({name: routeNames.ADMIN_LOGIN})
    } else if (to.name === routeNames.ADMIN_LOGIN && currentUser()) {
        next({name: routeNames.ADMIN})
    } else {
        next()
    }
})

export default router
