import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@babel/polyfill'
import VuetifySnackbarQueue from 'vuetify-snackbar-queue'

import * as firebase from './firebase'
import i18n from './i18n'

Vue.use(VuetifySnackbarQueue)
Vue.prototype.$firebase = firebase
Vue.config.productionTip = false
Vue.config.devtools = true

firebase.auth.onAuthStateChanged(() => {
    new Vue({
        router,
        store,
        vuetify,
        i18n,
        render: h => h(App)
    }).$mount('#app')
})
