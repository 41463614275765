import * as mutationTypes from './mutation-types'
import {contains, filter, map} from 'underscore'
import {v4 as uuid} from 'uuid'

const namespaced = true

const initialState = () => ({
    errors: []
})

const state = initialState()

const getters = {
    errors: state => state.errors,
    hasErrors: state => state.errors.length > 0
}

const actions = {
    addErrors ({commit}, data) {
        const items = map(data, (item) => ({
            ...item,
            uuid: uuid(),
            color: 'error'
        }))

        commit(mutationTypes.ADD_ERRORS, items)
        return Promise.resolve()
    },
    removeErrors ({commit}, uuids) {
        commit(mutationTypes.REMOVE_ERRORS, uuids)
        return Promise.resolve()
    },
    resetErrors ({commit}) {
        commit(mutationTypes.RESET_ERRORS)
        return Promise.resolve()
    }
}

const mutations = {
    [mutationTypes.ADD_ERRORS] (state, data) {
        state.errors = [...state.errors, ...data]
    },
    [mutationTypes.REMOVE_ERRORS] (state, uuids) {
        state.errors = filter(state.errors, (err) => !contains(uuids, err.uuid))
    },
    [mutationTypes.RESET_ERRORS]: (state) => Object.assign(state, initialState())
}

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
}
