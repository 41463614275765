import {findWhere} from 'underscore'

const EN_LOCALE = {flag: 'gb', value: 'en'}
const ES_LOCALE = {flag: 'co', value: 'es'}

export default {
    name: 'BaseLocaleSelect',
    props: {
        /**
         * The available locales
         */
        localeItems: {
            type: Array,
            default: () => [
                EN_LOCALE,
                ES_LOCALE
            ]
        },
        /**
         * The selected locale
         */
        localeValue: {
            type: String,
            default: EN_LOCALE.value
        }
    },
    computed: {
        selectedLocale () {
            const vm = this
            return findWhere(vm.localeItems, {value: vm.localeValue}) || EN_LOCALE
        }
    },
    methods: {
        handleClickLocale (val) {
            /**
             * @event click:locale
             * @type {string}
             */
            this.$emit('click:locale', val)
        }
    }
}
