import {auth, currentUser} from '@/firebase'
import * as mutationTypes from './mutation-types'

const namespaced = true

const initialState = () => ({
    signedIn: false
})

const state = initialState()

const getters = {
    authUser: () => currentUser()
}

const actions = {
    async signIn ({commit, dispatch}, data) {
        try {
            const response = await auth.signInWithEmailAndPassword(
                data.email_address,
                data.password
            )

            commit(mutationTypes.SIGN_IN)
            return response
        } catch (e) {
            dispatch('errors/addErrors', [e], {root: true})
            return Promise.reject(e)
        }
    },
    async signOut ({commit, dispatch}) {
        try {
            const response = await auth.signOut()

            commit(mutationTypes.SIGN_OUT)
            return response
        } catch (e) {
            dispatch('errors/addErrors', [e], {root: true})
            return Promise.reject(e)
        }
    }
}

const mutations = {
    [mutationTypes.SIGN_IN]: state => state.signedIn = true,
    [mutationTypes.SIGN_OUT]: state => state.signedIn = false
}

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations
}
