<template>
    <!-- ServiceWorkerSnackBar.vue -->
    <v-snackbar :color="color" :timeout="timeout" :value="value" bottom multi-line right>
        <v-row justify="center" no-gutters>
            <div class="text-subtitle-1 font-weight-bold mb-3">
                {{ title || $t('title') }}
            </div>
            <v-btn class="ml-3 white--text" color="success" small @click="handleClick">
                <v-icon class="mr-3" small>{{ buttonIcon }}</v-icon>
                {{ buttonText || $t('button') }}
            </v-btn>
        </v-row>
    </v-snackbar>
</template>

<script>
    export default {
        name: 'ServiceWorkerSnackbar',
        props: {
            /**
             * The snackbar timeout
             */
            timeout: {
                type: Number,
                default: -1
            },
            /**
             * Toggle the visibility of the snackbar
             */
            value: {
                type: Boolean,
                default: true
            },
            /**
             * The color of the snackbar
             */
            color: {
                type: String,
                default: 'primary'
            },
            /**
             * The title of the snackbar
             */
            title: {
                type: String,
                default: ''
            },
            /**
             * The icon of the snackbar action
             */
            buttonIcon: {
                type: String,
                default: 'mdi-refresh'
            },
            /**
             * The text of the snackbar action
             */
            buttonText: {
                type: String,
                default: ''
            }
        },
        methods: {
            handleClick () {
                /**
                 * @event click
                 */
                this.$emit('click')
            }
        }
    }
</script>

<i18n>
{
    "en": {
        "title": "A new version is available",
        "button": "Update"
    },
    "es": {
        "title": "Una nueva version esta disponible",
        "button": "Actualizar"
    }
}
</i18n>
