/*eslint-disable no-empty-pattern*/
import {db} from '@/firebase'
import * as mutationTypes from './mutation-types'

const initialState = () => ({
    invitations: [],
    invitation: {}
})

const mapDocument = (doc) => ({
    ...doc.data(),
    id: doc.id
})

export default {
    namespaced: true,
    state: initialState(),
    getters: {
        invitations: state => state.invitations,
        invitation: state => state.invitation
    },
    actions: {
        listInvitations: async ({commit, dispatch}) => {
            try {
                const response = await db.collection('invitations').get()
                let invitations = []

                response.forEach(doc => {
                    invitations.push(mapDocument(doc))
                })

                commit(mutationTypes.SET_INVITATIONS, invitations)
            } catch (e) {
                dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(e)
            }
        },
        showInvitation: async ({commit, dispatch}, id) => {
            try {
                const doc = await db.collection('invitations')
                    .doc(id)
                    .get()

                if (!doc.exists) {
                    const error = {
                        message: 'Invitation Not Found',
                        status: 404
                    }

                    return Promise.reject(error)
                }

                commit(mutationTypes.SET_INVITATION, mapDocument(doc))
            } catch (e) {
                dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(e)
            }
        },
        createInvitation: async ({dispatch}, data) => {
            try {
                const response = await db.collection('invitations')
                    .doc()
                    .set(data)

                return response
            } catch (e) {
                dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(e)
            }
        },
        updateInvitation: async ({dispatch}, data) => {
            try {
                const response = await db.collection('invitations')
                    .doc(data.id)
                    .update(data)

                return response
            } catch (e) {
                dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(e)
            }
        },
        removeInvitation: async ({dispatch}, id) => {
            try {
                const response = await db.collection('invitations')
                    .doc(id)
                    .delete()

                return response
            } catch (e) {
                dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(e)
            }
        },
        resetInvitations: ({commit}) => commit(mutationTypes.RESET_INVITATIONS),
        resetInvitation: ({commit}) => commit(mutationTypes.RESET_INVITATION)
    },
    mutations: {
        [mutationTypes.SET_INVITATIONS]: (state, data) => state.invitations = data,
        [mutationTypes.SET_INVITATION]: (state, data) => state.invitation = data,
        [mutationTypes.RESET_INVITATIONS]: state => Object.assign(state, initialState()),
        [mutationTypes.RESET_INVITATION]: state => state.invitation = initialState().invitation
    }
}
