<template>
    <!-- BaseCountryFlag.vue -->
    <img :src="`/${countryCode}.svg`" :width="width" class="centered-image">
</template>

<style scoped>
    .centered-image {
        vertical-align: middle
    }
</style>

<script>
    export default {
        name: 'BaseCountryFlag',
        props: {
            /**
             * Country code to display, i.e. gb
             */
            countryCode: {
                type: String,
                default: 'gb'
            },
            /**
             * The width of the flag
             */
            width: {
                type: Number,
                default: 32
            }
        }
    }
</script>
