export const isProduction = process.env.NODE_ENV === 'production'

export function log (...data) {
    if (!isProduction) {
        /* eslint-disable-next-line */
        console.log(...data)
    }
}

export function error (...data) {
    if (!isProduction) {
        /* eslint-disable-next-line */
        console.error(...data)
    }
}

export default {
    isProduction,
    error,
    log
}
