import Vue from 'vue'
import Vuex from 'vuex'

// modules
import auth from './modules/auth'
import errors from './modules/errors'
import invitations from './modules/invitations'
// plugins
import firebasePlugin from '@/store/plugins/firebase-plugin'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auth,
        errors,
        invitations
    },
    plugins: [firebasePlugin]
})
