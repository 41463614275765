import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: {
                primary: '#9cb9d1',
                secondary: '#759cd8',
                accent: '#094d74',
                error: '#E98074',
                success: '#AFD275',
                info: '#81d8d0'
            }
        }
    }
})
