<template>
    <!-- BaseAppFooter.vue -->
    <v-footer :color="color" app class="justify-center white--text font-weight-bold">
        <base-locale-select
            :locale-items="localeItems"
            :locale-value="localeValue"
            @click:locale="handleClickLocale"
        ></base-locale-select>
        <v-spacer></v-spacer>
        <v-btn class="mr-3" icon @click="handleClickIcon">
            <v-icon :color="iconColor">{{ icon }}</v-icon>
        </v-btn>
        {{ title }}
        <v-spacer></v-spacer>
        <slot></slot>
    </v-footer>
</template>

<script>
    import {APP_FOOTER} from '@/store/static/app'
    import BaseLocaleSelect from '@/components/BaseLocaleSelect'
    import BaseLocaleMixin from '@/mixins/base-locale.mixin'

    export default {
        name: 'BaseAppFooter',
        mixins: [BaseLocaleMixin],
        props: {
            /**
             * Designate the footer as part of the app layout
             */
            app: {
                type: Boolean,
                default: true
            },
            /**
             * The color of the footer
             */
            color: {
                type: String,
                default: 'accent'
            },
            /**
             * The icon color
             */
            iconColor: {
                type: String,
                default: 'info'
            },
            /**
             * The icon
             */
            icon: {
                type: String,
                default: 'mdi-heart'
            },
            /**
             * The footer title
             */
            title: {
                type: String,
                default: APP_FOOTER
            }
        },
        components: {
            BaseLocaleSelect
        },
        methods: {
            handleClickIcon () {
                /**
                 * @event click:icon
                 */
                this.$emit('click:icon')
            }
        }
    }
</script>
