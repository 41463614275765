import {auth, db} from '@/firebase'
import {SET_INVITATIONS} from '@/store/modules/invitations/mutation-types'
/* eslint-disable-next-line*/
import {Store} from 'vuex'

/**
 * @type {Store}
 */
let store

/**
 * @type {function}
 */
let unsubscribe

/**
 * Start snapshot subscriber to listen to invitation updates
 */
async function startInvitations () {
    if (unsubscribe) {
        await stopInvitations()
    }

    unsubscribe = db.collection('invitations').onSnapshot((snapshot) => {
        const invitations = []
        snapshot.forEach(doc => invitations.push({
            id: doc.id,
            ...doc.data()
        }))
        store.commit(`invitations/${SET_INVITATIONS}`, invitations)
    })
}

/**
 * Stop listening to invitation updates
 * @returns {Promise<void>}
 */
async function stopInvitations () {
    try {
        unsubscribe && typeof unsubscribe === 'function' && await unsubscribe()
        unsubscribe = undefined
    } catch (err) {
        store.dispatch('errors/addErrors', [err])
    }
}

/**
 * Factory Vuex Plugin Method
 * @param {Store} storeInstance
 */
export default (storeInstance) => {
    store = storeInstance

    auth.onAuthStateChanged(user => user ? startInvitations() : stopInvitations())
}
