export const HOME = 'HOME'
export const ADMIN = 'ADMIN'
export const ADMIN_LOGIN = 'ADMIN_LOGIN'
export const INVITATION = 'INVITATION'

export default {
    HOME,
    ADMIN,
    ADMIN_LOGIN,
    INVITATION
}
