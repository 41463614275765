import sections from '@/store/static/sections'
import {HOME} from '@/router/route-names'

export default {
    computed: {
        sectionLinks () {
            const vm = this

            return [
                {
                    route: vm.sectionRouteFormatted(`#${sections.STORY}`),
                    name: vm.$t('story_link')
                },
                {
                    route: vm.sectionRouteFormatted(`#${sections.WEDDING}`),
                    name: vm.$t('wedding_link')
                },
                {
                    route: vm.sectionRouteFormatted(`#${sections.GIFTS}`),
                    name: vm.$t('gifts_link')
                }
            ]
        },
        sectionRouteFormatted () {
            return (hash = '') => ({name: HOME, hash})
        }
    }
}
